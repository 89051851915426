<template>
  <div class="icon-with-text">
    <img :src="icon" alt="Icon" class="icon" />
    <span class="overline">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'IconWithText',
  props: {
    text: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.icon-with-text {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  min-height: 55px; /* Used to ensure all heights look good on mobile */
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.text {
  font-size: 16px;
  color: #333;
}
</style>