<template>
  <div class="slideshow">
    <div class="slides" ref="slides">
      <div v-for="(slide, index) in slides" :key="index" class="slide">
        <img :src="slide.image" :alt="slide.description" />
        <div class="description">{{ slide.description }}</div>
      </div>
    </div>

    <button class="prev" @click="prevSlide">‹</button>
    <button class="next" @click="nextSlide">›</button>
    <button class="pause" @click="toggleSlideshow">
      <template v-if="!isPaused">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="shadow"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#fff"
          >
            <path
              d="M360-320h80v-320h-80v320Zm160 0h80v-320h-80v320ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
            />
          </svg>
        </div>
      </template>
      <template v-else>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="shadow"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#fff"
        >
          <path
            d="m380-300 280-180-280-180v360ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
          />
        </svg>
      </template>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    slides: {
      type: Array,
      default: () => [
        {
          image: require("@/assets/mio_motel_sign.jpg"),
          description: "Motel sign",
        },
        {
          image: require("@/assets/mio_motel_front_office.jpg"),
          description: "Office",
        },
        {
          image: require("@/assets/mio_motel_front_view.jpg"),
          description: "Front",
        },
        {
          image: require("@/assets/mio_motel_shrub.jpg"),
          description: "Shrubbery",
        },
        {
          image: require("@/assets/mio_motel_second_story_view.jpg"),
          description: "Second floor view",
        },
        {
          image: require("@/assets/mio_motel_street_view.jpg"),
          description: "Complete view",
        },
        {
          image: require("@/assets/mio_motel_side_view.jpg"),
          description: "Sideview",
        },
        {
          image: require("@/assets/campfire_at_the_mio_motel.jpg"),
          description: "Campfire",
        }
      ],
    },
  },
  data() {
    return {
      currentSlide: 0,
      slideInterval: null,
      isPaused: false,
    };
  },
  methods: {
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    },
    prevSlide() {
      this.currentSlide =
        (this.currentSlide - 1 + this.slides.length) % this.slides.length;
    },
    startSlideshow() {
      this.slideInterval = setInterval(this.nextSlide, 3000);
      this.isPaused = false;
    },
    stopSlideshow() {
      clearInterval(this.slideInterval);
      this.isPaused = true;
    },
    toggleSlideshow() {
      if (this.isPaused) {
        this.startSlideshow();
      } else {
        this.stopSlideshow();
      }
    },
  },
  computed: {
    slideTransform() {
      return `translateX(-${this.currentSlide * 100}%)`;
    },
  },
  watch: {
    currentSlide() {
      this.$nextTick(() => {
        this.$refs.slides.style.transform = this.slideTransform;
      });
    },
  },
  mounted() {
    this.startSlideshow();
  },
  beforeUnmount() {
    this.stopSlideshow();
  },
};
</script>

<style scoped>
.slideshow {
  border-radius: 10px;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.shadow {
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  /* Similar syntax to box-shadow */
}

.slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  position: relative;
  min-width: 100%;
  box-sizing: border-box;
}

.slide img {
  width: 100%;
  display: block;
}

.description {
  position: absolute;
  bottom: 10px;
  left: 12px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
  /* border-radius: 10px; */
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
}

button.prev,
button.next,
button.pause {
  /* border-radius: 10px; */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
  z-index: 20;
}

button.prev {
  left: 10px;
}

button.next {
  right: 10px;
}

button.pause {
  position: absolute;
  top: 22px;
  left: 13px;
  /* width: 20px; */
  /* height: 30px; */
  background: transparent;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 1);
  /* border-radius: ; */
  padding: 0;
  /* top: -32px;
  left: 44%; */
  text-align: center;
}
</style>